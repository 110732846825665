import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AlertModule, ButtonModule, ModalModule } from '@mhe/ngx-shared';
import { TooltipModule } from '@mhe/ngx-shared/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ContentsMenuModule } from '../contents-menu/contents-menu.module';
import { EpubControlsModule } from '../epub-controls/epub-controls.module';
import { ReaderComponent } from './reader.component';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ConfirmationModalModule } from '@mhe/reader/components/modals/confirmation-modal';
import { TocModule } from '@mhe/reader/components/toc';
import { NavigationModule } from '@mhe/reader/components/navigation';
import { TopicsModule } from '@mhe/reader/components/topics';
import { ConceptNavigatorModule } from '@mhe/reader/components/concept-navigator';
import { SimpleMessageModalModule } from '@mhe/reader/components/modals/simple-message-modal';
import { EpubViewerModule } from '@mhe/reader/components/epub-viewer';
import { LoadingScreenModule } from '@mhe/reader/components/loading-screen';
import { AnnotationsContextMenuModule } from '@mhe/reader/components/annotations-context-menu';
import { SearchModule } from '@mhe/reader/components/search';
import { LargeImageNavigatorModule } from '@mhe/reader/components/large-image-navigator';
import { GlossaryModalModule } from '@mhe/reader/components/modals/glossary-modal';
import { IconRegistryModule } from '@mhe/reader/features/icons';
import { ElasticSearchModule } from '../elastic-search/elastic-search.module';
import { READER_STORE_PROVIDERS } from './state';
import { NgxAiAssistComponent } from '@mhe/ngx-ai-assist';
import { OrphanAnnotationsModalComponent } from '@mhe/reader/components/modals';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatSidenavModule,
    TranslateModule,
    // ngx-shared
    AlertModule,
    ButtonModule,
    ModalModule,
    TooltipModule,
    // reader features
    AnnotationsContextMenuModule,
    ConceptNavigatorModule,
    ConfirmationModalModule,
    EpubControlsModule,
    EpubViewerModule,
    GlossaryModalModule,
    IconRegistryModule,
    LargeImageNavigatorModule,
    LoadingScreenModule,
    NavigationModule,
    SearchModule,
    ElasticSearchModule,
    SimpleMessageModalModule,
    TocModule,
    TopicsModule,
    // children
    ContentsMenuModule,
    EpubControlsModule,
    ToolbarModule,
    NgxAiAssistComponent,
    OrphanAnnotationsModalComponent,
  ],
  declarations: [ReaderComponent],
  exports: [ReaderComponent],
  providers: [
    // Moved here from ReaderComponent providers for the ReaderApiLoggingInterceptor
    ...READER_STORE_PROVIDERS,
    { provide: Window, useValue: window },
  ],
})
export class ReaderModule {}
