<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngIf="book$ | async">
    <mat-sidenav-container *ngIf="(readerStore.hideReader$ | async) === false" class="sidenav-container">
      <!-- contents drawer -->
      <mat-sidenav
        #leftDrawer
        mode="over"
        hasBackdrop="true"
        class="rdrx-drawer"
        [opened]="tocOpen$ | async"
        (closedStart)="closeLeftDrawer()"
      >
        <rdrx-contents-menu class="drawer-container" (closeEvent)="leftDrawer.close()"></rdrx-contents-menu>
      </mat-sidenav>

      <!-- search drawer -->
      <mat-sidenav
        #rightDrawer
        class="search-sidenav rdrx-drawer"
        position="end"
        mode="over"
        hasBackdrop="true"
        [opened]="searchOpen$ | async"
        (closedStart)="closeRightDrawer()"
      >
        <ng-container *ngIf="enableElasticSearch$ | async; then dfaSearch; else coreSearch"></ng-container>
        <ng-template #coreSearch>
          <reader-core-search
            class="drawer-container"
            [attr.aria-label]="'search.search' | translate"
            (closeEvent)="rightDrawer.close()"
          ></reader-core-search>
        </ng-template>
        <ng-template #dfaSearch>
          <reader-elastic-search
            class="drawer-container reader-search"
            [loading]="loading$ | async"
            [suggestions]="suggestions$ | async"
            [topics]="topics$ | async"
            [quizzes]="quizzes$ | async"
            [nodes]="nodes$ | async"
            [totalResults]="totalResults$ | async"
            (suggestionClicked)="handleSuggestionClicked($event)"
            (searchQueryChanged)="handleSearchQueryChanged($event)"
            (resultClicked)="handleSearchResultClicked($event)"
            (closeEvent)="rightDrawer.close()"
          ></reader-elastic-search>
        </ng-template>
      </mat-sidenav>

      <!-- content -->
      <mat-sidenav-content>
        <div class="reader-layout" [class.ai-assist-active]="(showAiAssist$ | async) === true">
          <div class="rdrx-content-region">
            <!-- toolbar -->
            <reader-core-toolbar
              *ngIf="navbar$ | async"
              role="region"
              [attr.aria-label]="'navigation.navigation' | translate"
            ></reader-core-toolbar>

            <div class="rdrx-content-container">
              <!-- alerts -->
              <div class="epub-alerts">
                <mhe-alert
                  *ngIf="(digitalSample$ | async) && showDigitalSampleAlert"
                  id="digital-sample-message"
                  [type]="AlertType.Warning"
                  [smallAlert]="true"
                  [closable]="true"
                  (close)="handleDigitalSampleAlertClose()"
                >
                  {{ 'alert.digital_sample_message' | translate }}
                </mhe-alert>
                
                <mhe-alert
                *ngIf="(showFallbackBannerForEvergreen$ | async)"
                id="invalid-release-version-banner"
                [type]="AlertType.Warning"
                [smallAlert]="true"
                [closable]="false"
              >
                {{ 'alert.invalid_releaseId_banner' | translate }}
                 <a href="https://www.mheducation.com/support.html" target="_blank">{{ 'alert.invalid_releaseId_banner_link' | translate }}</a>
              </mhe-alert>

                <mhe-alert
                *ngIf="(showFallbackBannerForEvergreen$ | async)"
                id="invalid-release-version-banner"
                [type]="AlertType.Warning"
                [smallAlert]="true"
                [closable]="false"
              >
                {{ 'alert.invalid_releaseId_banner' | translate }}
                 <a href="https://www.mheducation.com/support.html" target="_blank">{{ 'alert.invalid_releaseId_banner_link' | translate }}</a>
              </mhe-alert>

              <mhe-alert
                *ngIf="(readerStore.pdfUrl$ | async)"
                data-automation-id="pdf-ready"
                [type]="AlertType.Info"
                [smallAlert]="true"
                [closable]="true"
                (close)="closePdfAlert()"
              >
                {{ 'print.file_ready' | translate }}&nbsp;
                <a [href]="printLink$ | async" target="_blank" data-automation-id="pdf-link">
                  {{ printTitle$ | async }}
                </a>
              </mhe-alert>

              <mhe-alert
                *ngIf="quickAnnotationEnabled$ | async"
                id="quick-annotation-message"
                [type]="AlertType.Warning"
                [smallAlert]="true"
                [closable]="true"
                (close)="readerStore.toggleQuickAnnotationEnabled()"
              >
                {{ 'annotation.quick_annotation_message' | translate }}
              </mhe-alert>

                <mhe-alert *ngIf="assignmentReview$ | async" [type]="AlertType.Warning" [closable]="false">
                  {{ 'assignment.review_mode' | translate }}
                </mhe-alert>

                <mhe-alert *ngIf="showSessionError$ | async" [smallAlert]="true" [type]="AlertType.Error">
                  {{ 'dialog.session_ended_error' | translate }}
                </mhe-alert>

                <mhe-alert
                  *ngIf="documentRequestError$ | async"
                  [type]="AlertType.Warning"
                  (close)="closeDocumentRequestAlert()"
                >
                  {{ 'dialog.document_request_error' | translate }}
                </mhe-alert>

                <div *ngIf="showAlerts$ | async">
                  <mhe-alert
                    *ngFor="let alertGroup of groupedAlerts$ | async; trackBy: trackByAlertKey"
                    [smallAlert]="true"
                    [type]="alertGroup.alert.alertType"
                    (close)="handleAlertDismiss(alertGroup.alert.translateKey)"
                  >
                    {{ alertGroup.alert.translateKey | translate }}
                    <span *ngIf="alertGroup.count > 1" class="alert-count"> (x{{ alertGroup.count }}) </span>
                  </mhe-alert>
                </div>
              </div>

              <!-- epub controls -->
              <reader-core-epub-controls
                *ngIf="showEpubControls$ | async"
                [isFixedLayout]="isFixedLayout$ | async"
                [isDoubleSpread]="isDoubleSpread$ | async"
                [isSinglePageViewOnly] = "isSinglePageViewOnly$ | async"
                [albumMode]="albumMode$ | async"
                [zoomLevel]="epubZoomLevel$ | async"
                [increaseEpubZoomEnabled]="increaseEpubZoomEnabled$ | async"
                [decreaseEpubZoomEnabled]="decreaseEpubZoomEnabled$ | async"
                [enableUpArrow]="zoomMoveUpEnabled$ | async"
                [enableDownArrow]="zoomMoveDownEnabled$ | async"
                [enableLeftArrow]="zoomMoveLeftEnabled$ | async"
                [enableRightArrow]="zoomMoveRightEnabled$ | async"
                [enableTTS]="enableTTS$ | async"
                [showReadSpeakerVoicePick]="showReadSpeakerVoicePick$ | async"
                [isAiAssistOffered]="aiAssistIsOffered$ | async"
                (toggleAlbumMode)="onToggleAlbumMode()"
                (toggleAiAssistPanel)="onToggleAiAssistPanel()"
                (increaseEpubZoomLevel)="onIncreaseEpubZoomLevel()"
                (decreaseEpubZoomLevel)="onDecreaseEpubZoomLevel()"
                (moveEpub)="onMoveZoomedEpub($event)"
              ></reader-core-epub-controls>

              <reader-core-concept-navigator></reader-core-concept-navigator>

              <!-- epub viewer -->
              <reader-core-epub-ui-viewer
                [isDoubleSpread]="isDoubleSpread$ | async"
                [albumMode]="albumMode$ | async"
              >
              </reader-core-epub-ui-viewer>
            </div>

            <!-- footer -->
            <ng-container *ngIf="showFooter$ | async">
              <footer>
                <div class="rdrx-content-container">
                  <div
                    *ngIf="scrubber$ | async"
                    class="scrubber"
                    role="region"
                    [attr.aria-label]="'scrubber.chapter_navigation' | translate"
                  >
                    <reader-core-scrubber></reader-core-scrubber>
                  </div>

                  <div
                    *ngIf="assignmentActive$ | async"
                    class="assignment"
                    role="region"
                    [attr.aria-label]="'assignment.submit_btn' | translate"
                  >
                    <mhe-alert
                      *ngIf="backToAssignmentButton$ | async"
                      [type]="AlertType.Warning"
                      [closable]="false"
                      [ngClass]="footer - alert"
                    >
                      {{ 'assignment.warning_page_out_of_range' | translate }}
                    </mhe-alert>

                    <button
                      *ngIf="(backToAssignmentButton$ | async) === false"
                      mhe-button
                      [purpose]="ButtonPurpose.Primary"
                      [disabled]="
                        (submitAssignmentDisabled$ | async) ||
                        ((this.readerConfigStore.pageStartCfi$ | async) &&
                          (this.readerConfigStore.pageEndCfi$ | async) &&
                          false === (isLastPage$ | async))
                      "
                      (click)="submitAssignment()"
                    >
                      <ng-container *ngIf="(k5$ | async) === false">
                        {{ 'assignment.submit_btn' | translate }}
                      </ng-container>
                      <ng-container *ngIf="k5$ | async">
                        <mat-icon svgIcon="k5:checkmark" class="rdrx-k5-icon"></mat-icon>
                        <span translate> assignment.submit_k5_btn </span>
                      </ng-container>
                    </button>
                    <button
                      [class]="(k5$ | async) ? 'k5-back-assignment-btn' : ''"
                      *ngIf="backToAssignmentButton$ | async"
                      mhe-button
                      [purpose]="ButtonPurpose.Primary"
                      (click)="navigateToPageStart()"
                    >
                      <ng-container *ngIf="(k5$ | async) === false">
                        {{ 'assignment.back_assignment_btn' | translate }}
                      </ng-container>

                      <ng-container *ngIf="k5$ | async">
                        <span translate> assignment.back_assignment_btn </span>
                      </ng-container>
                    </button>
                  </div>
                </div>
              </footer>
            </ng-container>
          </div>

          <div class="rdrx-content-ai-assist">
            <ngx-ai-assist
              *ngIf="(aiAssistIsEnabled$ | async) === true"
              [hidden]="(showAiAssist$ | async) === true"
              [panelConfig]="aiAssistPanelConfig | async"
              [userSelection]="annotationForAiAssist$ | async"
              (closeAiAssistPanelEvent)="onCloseAiAssistPanel()"
              (componentIsReady)="onAiAssistIsReady($event)"
              (navigateAiAssistPanelEvent)="onNavFromAiAssistPanel($event)"
            ></ngx-ai-assist>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</ng-container>

<rdrx-loading-screen *ngIf="loading$ | async"></rdrx-loading-screen>
